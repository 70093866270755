<script setup lang="ts">
import { KsIcon, KsButton } from "@aschehoug/kloss";

// FIXME: Replace with actual user info
const user = ref<{ firstName?: string } | null>(null);
</script>

<template>
  <header class="bg-blurple-100 text-neutral-white w-full p-lg">
    <div class="container flex justify-between h-5 items-center m-auto">
      <NuxtLink
        to="/"
        class="h-full"
      >
        <LogoJuridika class="h-full w-fit" />
      </NuxtLink>

      <nav>
        <ul class="flex gap-xs md:gap-xl items-center">
          <li>
            <NuxtLink
              to="/handlekurv"
              class="flex gap-xs items-center"
            >
              <KsIcon id="bag-shopping-solid" />
              Handlekurv
            </NuxtLink>
          </li>
          <li v-if="user">
            <NuxtLink
              to="/min-side"
              class="flex gap-xs items-center"
            >
              <span
                class="bg-neutral-white text-blurple-100 text-center rounded-full size-md"
                v-text="user.firstName?.charAt(0)"
              />
              Min side
            </NuxtLink>
          </li>
          <li>
            <KsButton
              variant="tertiary"
              shape="round"
              class="menu-button"
            >
              <KsIcon id="bars-regular" />
            </KsButton>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<style scoped>
.menu-button {
  --ks-secondary: rgb(var(--juridika-color-neutral-white));
  --ks-input: rgb(var(--juridika-color-blurple-80));
}
</style>

<script setup lang="ts">
const data = {
  contact: [
    { href: "mailto:post@juridika.no", label: "post@juridika.no" },
    { href: "tel:22400301", label: "22 40 03 01" },
  ],
  information: [
    {
      label: "Bli forfatter",
      href: "/bli-forfatter",
    },
    {
      label: "Brukervilkår",
      href: "/brukervilkar",
    },
    {
      label: "Personvern",
      href: "/personvern",
    },
    {
      label: "Informasjonskapsler",
      href: "/informasjonskapsler",
    },
  ],
};
</script>

<template>
  <div class="flex flex-wrap gap-xl">
    <FooterColophonList
      title="Kontakt"
      :items="data.contact"
    >
      <address class="not-italic leading-8">
        Postboks 508 Sentrum<br />0105 Oslo
      </address>
    </FooterColophonList>
    <FooterColophonList
      title="Informasjon"
      :items="data.information"
    />
    <FooterColophonList
      title="Spørsmål?"
    >
      Besøk våre
      <NuxtLink
        class="text-blue-60 underline"
        to="/hjelp"
      >
        hjelpesider
      </NuxtLink>
    </FooterColophonList>
  </div>
</template>

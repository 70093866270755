<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="225"
    height="33"
    viewBox="0 0 225 33"
    fill="none"
  >
    <g clip-path="url(#clip0_256_1255)">
      <path
        d="M42.3667 7.09766V19.4843C42.3667 21.7499 43.5585 23.1391 45.7254 23.1391C47.8923 23.1391 49.0842 21.7582 49.0842 19.4843V7.09766H51.2511V19.6331C51.2511 22.8414 49.2842 25.0327 45.7338 25.0327C42.1833 25.0327 40.1914 22.8331 40.1914 19.6331V7.09766H42.3667Z"
        fill="white"
      />
      <path
        d="M54.168 12.2815H56.0849V14.572C56.735 12.819 57.8517 11.959 59.5436 11.959C61.7356 11.959 62.8274 13.3399 62.8274 16.1761V24.6102H60.8105V16.3497C60.8105 14.5472 60.1354 13.6872 58.7436 13.6872C57.1017 13.6872 56.2099 15.0681 56.2099 17.805V24.6102H54.168V12.2815Z"
        fill="white"
      />
      <path
        d="M65.7188 7.41992H67.9857V10.0825H65.7188V7.41992ZM65.8437 12.2819H67.8856V24.619H65.8437V12.2819Z"
        fill="white"
      />
      <path
        d="M70.0859 12.2812H72.2281L74.42 20.5914C74.5951 21.2611 74.7198 21.873 74.82 22.4684H74.8949C75.0203 21.873 75.145 21.2611 75.3201 20.5914L77.4868 12.2812H79.629L75.945 24.6183H73.7783L70.0859 12.2812Z"
        fill="white"
      />
      <path
        d="M90.0966 18.9214H82.8877C82.9626 21.7576 83.7794 23.1385 85.7212 23.1385C87.0382 23.1385 87.9797 22.4439 88.255 20.848L90.0721 21.3938C89.6469 23.709 88.0049 24.9246 85.6715 24.9246C82.9129 24.9246 80.8457 23.246 80.8457 18.4584C80.8457 13.6707 82.9879 11.9922 85.6211 11.9922C88.4546 11.9922 90.0966 13.894 90.0966 17.3421V18.9214ZM88.0385 17.3917C88.0385 14.7043 87.1965 13.5716 85.6295 13.5716C84.1626 13.5716 82.971 14.3157 82.8961 17.3917H88.0385Z"
        fill="white"
      />
      <path
        d="M98.1153 12.0088C98.24 12.0088 98.3401 12.0088 98.4648 12.0336V14.1339H98.1902C97.0729 14.1339 96.3983 14.5804 95.9066 15.2418C95.0645 16.2259 95.0393 17.6316 95.0393 18.9463V24.6187H92.998V12.2816H94.8894V15.0931C95.3314 13.4393 96.3479 12.0088 98.1153 12.0088Z"
        fill="white"
      />
      <path
        d="M99.9473 20.6908L101.964 20.3767C102.114 22.2041 103.081 23.2873 104.498 23.2873C105.84 23.2873 106.615 22.6174 106.615 21.3855C106.615 20.2526 106.14 19.6324 104.823 19.3596L102.956 18.9875C101.139 18.6154 100.122 17.3089 100.122 15.5312C100.122 13.3316 101.889 11.9756 104.273 11.9756C106.781 11.9756 108.473 13.282 108.548 15.9197L106.582 16.2422C106.456 14.3652 105.59 13.5549 104.273 13.5549C102.981 13.5549 102.156 14.2494 102.156 15.4319C102.156 16.4407 102.606 17.0361 103.873 17.3089L105.815 17.7306C107.882 18.1771 108.674 19.4092 108.674 21.2614C108.674 23.3865 107.132 24.9162 104.473 24.9162C101.839 24.9162 100.148 23.5023 99.9473 20.6908Z"
        fill="white"
      />
      <path
        d="M111.342 7.41992H113.609V10.0825H111.342V7.41992ZM111.466 12.2819H113.508V24.619H111.466V12.2819Z"
        fill="white"
      />
      <path
        d="M115.492 12.2813H117.409V9.17227L119.426 8.40332V12.2731H122.01V13.8772H119.426V21.0545C119.426 22.3362 119.876 22.8819 120.968 22.8819C121.443 22.8819 121.718 22.8323 122.059 22.7579V24.6101C121.585 24.7341 121.118 24.8085 120.443 24.8085C118.159 24.8085 117.409 23.5021 117.409 21.3522V13.8772H115.492V12.2813Z"
        fill="white"
      />
      <path
        d="M132.952 18.9214H125.743C125.818 21.7576 126.635 23.1385 128.577 23.1385C129.894 23.1385 130.835 22.4439 131.11 20.848L132.928 21.3938C132.502 23.709 130.86 24.9246 128.527 24.9246C125.768 24.9246 123.701 23.246 123.701 18.4584C123.701 13.6707 125.843 11.9922 128.477 11.9922C131.311 11.9922 132.952 13.894 132.952 17.3421V18.9214ZM130.886 17.3917C130.886 14.7043 130.044 13.5716 128.477 13.5716C127.01 13.5716 125.818 14.3157 125.743 17.3917H130.886Z"
        fill="white"
      />
      <path
        d="M134.277 12.2813H136.194V9.17227L138.211 8.40332V12.2731H140.795V13.8772H138.211V21.0545C138.211 22.3362 138.661 22.8819 139.753 22.8819C140.228 22.8819 140.495 22.8323 140.845 22.7579V24.6101C140.37 24.7341 139.903 24.8085 139.228 24.8085C136.944 24.8085 136.194 23.5021 136.194 21.3522V13.8772H134.277V12.2813Z"
        fill="white"
      />
      <path
        d="M142.662 20.6913L144.679 20.3688C144.829 22.1962 145.795 23.2795 147.213 23.2795C148.555 23.2795 149.33 22.6096 149.33 21.3776C149.33 20.2448 148.855 19.6246 147.538 19.3518L145.671 18.9797C143.854 18.6075 142.837 17.3011 142.837 15.5233C142.837 13.3238 144.604 11.9678 146.988 11.9678C149.496 11.9678 151.188 13.2742 151.263 15.9119L149.296 16.2344C149.171 14.3574 148.305 13.5471 146.988 13.5471C145.696 13.5471 144.871 14.2416 144.871 15.4241C144.871 16.4329 145.321 17.0283 146.588 17.3011L148.53 17.7228C150.596 18.1693 151.388 19.4014 151.388 21.2536C151.388 23.3786 149.847 24.9083 147.187 24.9083C144.546 24.9166 142.862 23.5027 142.662 20.6913Z"
        fill="white"
      />
      <path
        d="M159.08 13.8857H156.497V24.6187H154.48V13.8857H152.588V12.2816H154.48V10.0573C154.48 7.93224 155.721 6.57617 157.739 6.57617C158.263 6.57617 158.705 6.62578 159.08 6.72501V8.42835C158.73 8.37875 158.405 8.35395 158.088 8.35395C156.972 8.35395 156.497 8.92452 156.497 10.2558V12.2816H159.08V13.8857Z"
        fill="white"
      />
      <path
        d="M160.072 18.4506C160.072 14.0102 161.939 11.9844 164.948 11.9844C167.957 11.9844 169.823 14.0102 169.823 18.4506C169.823 22.8909 167.957 24.9168 164.948 24.9168C161.939 24.9168 160.072 22.8909 160.072 18.4506ZM167.657 18.4506C167.657 14.7461 166.515 13.5885 164.948 13.5885C163.381 13.5885 162.239 14.7461 162.239 18.4506C162.239 22.1549 163.381 23.3126 164.948 23.3126C166.515 23.3126 167.657 22.1467 167.657 18.4506Z"
        fill="white"
      />
      <path
        d="M177.507 12.0088C177.631 12.0088 177.731 12.0088 177.856 12.0336V14.1339H177.582C176.464 14.1339 175.79 14.5804 175.298 15.2418C174.456 16.2259 174.431 17.6316 174.431 18.9463V24.6187H172.389V12.2816H174.281V15.0931C174.723 13.4393 175.748 12.0088 177.507 12.0088Z"
        fill="white"
      />
      <path
        d="M181.974 24.6188H179.932V6.7334H181.974V24.6188Z"
        fill="white"
      />
      <path
        d="M184.492 21.4103C184.492 20.0543 185.067 19.1199 185.884 18.4997C186.601 17.954 187.576 17.615 189.143 17.3421L191.259 16.97V16.2093C191.259 14.3819 190.634 13.5964 189.193 13.5964C187.45 13.5964 186.926 14.6548 186.884 16.2093H184.792C184.842 12.9266 186.984 11.9922 189.193 11.9922C192.101 11.9922 193.293 13.3235 193.293 16.3829V20.9721C193.293 22.5266 193.393 23.6842 193.593 24.6269H191.576C191.476 23.9075 191.401 23.1219 191.401 22.4274C190.976 23.9323 189.71 24.9163 187.967 24.9163C185.733 24.9163 184.492 23.5023 184.492 21.4103ZM191.284 20.1039V18.4253L189.092 18.847C187.525 19.1447 186.684 19.8558 186.659 21.2119C186.634 22.568 187.309 23.2873 188.626 23.2873C190.209 23.2873 191.284 22.0718 191.284 20.1039Z"
        fill="white"
      />
      <path
        d="M203.227 12.8771C204.119 13.6213 204.569 14.7541 204.569 16.1846C204.569 18.7728 203.152 20.4017 200.393 20.4017C199.677 20.4017 199.027 20.2777 198.477 20.0544C198.051 20.2529 197.752 20.6249 197.752 21.1128C197.752 21.7826 198.252 22.2208 199.394 22.32L202.177 22.5681C204.319 22.7666 205.711 23.9738 205.711 25.9253C205.711 28.3149 203.919 29.7206 200.468 29.7206C197.135 29.7206 195.543 28.3893 195.543 26.4379C195.543 25.2554 196.26 24.288 197.585 23.8994C196.568 23.5025 196.068 22.7418 196.068 21.8239C196.068 20.7655 196.768 20.0462 197.785 19.6741C196.743 18.9546 196.193 17.7475 196.193 16.1929C196.193 13.6544 197.66 11.9758 200.393 11.9758C201.186 11.9758 201.885 12.1247 202.46 12.3975C202.835 11.1159 203.752 10.2725 205.169 10.2725C205.369 10.2725 205.544 10.2973 205.744 10.3469V12.0254C205.469 11.951 205.244 11.9262 204.952 11.9262C204.152 11.9345 203.552 12.2818 203.227 12.8771ZM198.902 24.1722C198.777 24.1722 198.652 24.1474 198.552 24.1226C197.81 24.4948 197.435 25.1314 197.435 25.9997C197.435 27.5294 198.652 28.2901 200.594 28.2901C202.835 28.2901 203.727 27.5046 203.727 26.165C203.727 25.0074 203.036 24.4617 201.661 24.3624L198.902 24.1722ZM202.585 16.1764C202.585 14.2993 201.744 13.365 200.393 13.365C199.027 13.365 198.177 14.3241 198.177 16.2011C198.177 18.0782 199.043 19.0622 200.393 19.0622C201.735 19.0622 202.585 18.0782 202.585 16.1764Z"
        fill="white"
      />
      <path
        d="M216.27 18.9214H209.062C209.136 21.7576 209.953 23.1385 211.895 23.1385C213.212 23.1385 214.154 22.4439 214.429 20.848L216.245 21.3938C215.821 23.709 214.179 24.9246 211.845 24.9246C209.086 24.9246 207.02 23.246 207.02 18.4584C207.02 13.6707 209.161 11.9922 211.795 11.9922C214.628 11.9922 216.27 13.894 216.27 17.3421V18.9214ZM214.203 17.3917C214.203 14.7043 213.362 13.5716 211.795 13.5716C210.328 13.5716 209.136 14.3157 209.062 17.3917H214.203Z"
        fill="white"
      />
      <path
        d="M217.596 12.2813H219.512V9.17227L221.529 8.40332V12.2731H224.113V13.8772H221.529V21.0545C221.529 22.3362 221.979 22.8819 223.071 22.8819C223.547 22.8819 223.821 22.8323 224.163 22.7579V24.6101C223.688 24.7341 223.221 24.8085 222.546 24.8085C220.263 24.8085 219.512 23.5021 219.512 21.3522V13.8772H217.596V12.2813Z"
        fill="white"
      />
      <path
        d="M0 26.8239C0 30.0239 2.04362 32.2234 5.70154 32.2234H14.8635C18.53 32.2234 20.5479 30.0239 20.5479 26.8239V0H18.3154V26.6834C18.3154 28.9572 17.0875 30.3381 14.8549 30.3381C12.6224 30.3381 11.3945 28.9572 11.3945 26.6834V0H0V26.8239ZM2.25829 26.6834V1.90182H9.15341V26.8322C9.15341 28.2213 9.53981 29.4203 10.2782 30.3381H5.71013C3.48618 30.3299 2.25829 28.949 2.25829 26.6834Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_256_1255">
        <rect
          width="224.163"
          height="32.2234"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="153"
    height="36"
    viewBox="0 0 153 36"
    fill="none"
  >
    <path
      d="M85.5378 25.3037C85.4516 27.2007 84.7618 28.451 83.0803 28.451H79.545V19.3541H79.8037C81.5282 19.4403 82.0887 19.699 82.0887 21.596V22.0271L83.2959 21.8115V15.905L82.0887 16.0343V16.293C82.0887 18.19 81.5282 18.4487 79.8037 18.5349H79.545V10.3433H82.6061C84.2875 10.3433 84.9342 11.5936 85.0204 13.4906V13.9218L86.5294 13.8355L86.2276 8.14453L84.7186 8.23076L84.7618 9.22237H75.5354L75.4492 10.214C75.4492 10.214 75.7079 10.2571 75.9666 10.3002C76.4408 10.4296 76.6995 10.6882 76.6995 11.1625V27.675C76.6995 28.1492 76.4408 28.4079 75.9666 28.5373C75.7079 28.5804 75.5786 28.6235 75.5786 28.6235L75.4923 29.6151H78.5103H85.3653L85.3222 30.6067L86.8312 30.6929L87.133 25.002L85.624 24.9157L85.5378 25.3037Z"
      fill="white"
    />
    <path
      d="M32.2061 27.6746L27.6792 10.2136C25.9978 3.61724 21.6864 1.41845 17.1164 1.24599C12.417 1.03042 9.39902 4.13461 9.31279 6.50586C9.26968 8.31663 10.3906 9.26513 11.9427 9.26513L12.8912 8.31663C9.18345 8.31663 10.52 2.06515 16.3403 2.06515C23.2816 2.06515 24.3163 8.96333 24.3163 8.96333L20.4792 20.2591H19.0565C17.375 20.1729 17.1164 19.7417 17.7199 17.931L17.8493 17.5429L16.7283 17.4136L14.7882 23.3202L15.8661 23.4495L15.9954 23.1046C16.5559 21.3369 17.2888 21.5094 18.9271 21.4232H20.0912L19.3151 23.6651C17.5906 28.4938 13.4517 33.5812 8.36429 33.6243C4.13915 33.6674 2.28527 30.865 2.45772 28.1058C2.50083 27.6315 2.54395 27.2435 2.67329 26.8555C3.57868 27.071 4.65652 27.071 5.94993 26.7261C9.39902 25.8208 9.87327 23.8375 9.48524 23.0184C9.14034 22.2854 8.0625 21.8112 6.25172 22.2423C4.74275 22.6303 3.23377 23.4926 2.11281 24.7429C-0.215324 21.1645 4.09604 18.4483 4.09604 18.4483L3.57868 18.1896C1.50922 19.1381 -0.215324 21.3801 0.0433578 23.8375C0.129585 24.786 0.517609 25.5621 1.16431 26.1225C0.862518 26.7261 0.64695 27.3728 0.517609 28.1058C-0.129096 32.762 4.65652 34.9177 8.83854 34.5728C15.5211 34.0123 19.5738 29.0974 21.169 24.5273L22.2469 21.3801H27.6792L29.3175 27.6315C29.4468 28.1058 29.0588 28.3645 28.5846 28.4938C28.3259 28.5369 27.7654 28.58 27.7654 28.58L27.6792 29.5716H33.8013L33.8875 28.58C33.8875 28.58 33.1546 28.5369 32.8959 28.4938C32.5941 28.4507 32.3355 28.1489 32.2061 27.6746ZM5.34634 23.5357C6.29484 23.1046 7.58825 22.9753 7.80381 23.8806C8.01938 24.8291 7.07088 25.3896 6.38106 25.6483C5.94993 25.8208 4.22538 26.2519 3.06131 25.7345C3.75113 24.4842 4.78586 23.7944 5.34634 23.5357ZM22.678 20.216L25.3511 12.5418L27.3774 20.2591L22.678 20.216Z"
      fill="white"
    />
    <path
      d="M72.4312 27.6744V11.1188C72.4312 10.6446 72.733 10.3859 73.1641 10.2997C73.4228 10.2566 73.5521 10.2134 73.5521 10.2134L73.6384 9.22182H68.4216L68.3354 10.2134C68.3354 10.2134 68.5941 10.2566 68.8528 10.2997C69.327 10.429 69.6288 10.7308 69.6288 11.2051V18.405H64.4552V11.1188C64.4552 10.6446 64.7138 10.3859 65.1881 10.2566C65.4468 10.2134 65.5761 10.1703 65.5761 10.1703L65.6624 9.17871H60.4456L60.3594 10.1703C60.3594 10.1703 60.6181 10.2134 60.8767 10.2566C61.351 10.3859 61.6097 10.6446 61.6097 11.1188V27.6744C61.6097 28.1487 61.351 28.4074 60.8767 28.5367C60.6181 28.5798 60.4887 28.6229 60.4887 28.6229L60.4025 29.6146H65.6192L65.7055 28.6229C65.7055 28.6229 65.4468 28.5798 65.1881 28.5367C64.7138 28.4074 64.4552 28.1487 64.4552 27.6744V19.526H69.6288V27.6313C69.6288 28.1056 69.327 28.4074 68.8528 28.5367C68.5941 28.5798 68.4647 28.6229 68.4647 28.6229L68.3785 29.6146H73.5953L73.6815 28.6229C73.6815 28.6229 73.4228 28.5798 73.1641 28.5367C72.6899 28.4074 72.4312 28.1487 72.4312 27.6744Z"
      fill="white"
    />
    <path
      d="M100.843 27.6744V11.1188C100.843 10.6446 101.145 10.3859 101.576 10.2997C101.835 10.2566 101.964 10.2134 101.964 10.2134L102.051 9.22182H96.8339L96.7476 10.2134C96.7476 10.2134 97.0063 10.2566 97.265 10.2997C97.7393 10.429 98.0411 10.7308 98.0411 11.2051V18.405H92.8674V11.1188C92.8674 10.6446 93.1261 10.3859 93.6004 10.2566C93.859 10.2134 93.9884 10.1703 93.9884 10.1703L94.0746 9.17871H88.8147L88.7285 10.1703C88.7285 10.1703 88.9872 10.2134 89.2459 10.2566C89.7201 10.3859 89.9788 10.6446 89.9788 11.1188V27.6744C89.9788 28.1487 89.7201 28.4074 89.2459 28.5367C88.9872 28.5798 88.8579 28.6229 88.8579 28.6229L88.7716 29.6146H93.9884L94.0746 28.6229C94.0746 28.6229 93.8159 28.5798 93.5572 28.5367C93.083 28.4074 92.8243 28.1487 92.8243 27.6744V19.526H97.9979V27.6313C97.9979 28.1056 97.6961 28.4074 97.2219 28.5367C96.9632 28.5798 96.8339 28.6229 96.8339 28.6229L96.7476 29.6146H101.964L102.051 28.6229C102.051 28.6229 101.792 28.5798 101.533 28.5367C101.102 28.4074 100.843 28.1487 100.843 27.6744Z"
      fill="white"
    />
    <path
      d="M109.423 8.87695C106.017 8.87695 103.99 10.9895 103.99 14.9991V23.708C103.99 27.6745 106.06 29.8302 109.423 29.8302C112.829 29.8302 114.855 27.7176 114.855 23.708V14.9991C114.898 11.0326 112.829 8.87695 109.423 8.87695ZM112.052 23.8374C112.052 27.5883 111.19 28.8386 109.423 28.8386C107.655 28.8386 106.836 27.5883 106.836 23.8374V14.8697C106.836 11.1189 107.698 9.86857 109.466 9.86857C111.233 9.86857 112.052 11.1189 112.052 14.8697V23.8374Z"
      fill="white"
    />
    <path
      d="M55.9177 23.8377C55.9177 27.5886 55.0554 28.8389 53.2878 28.8389C51.5201 28.8389 50.701 27.5886 50.701 23.8377V14.8701C50.701 11.1192 51.5632 9.86893 53.3309 9.86893C54.9261 9.86893 55.7884 10.8605 55.9177 13.8785L58.7201 14.051V8.1875H57.2111V9.52402C57.2111 9.91204 56.9524 10.0845 56.6506 9.86893C55.7884 9.26534 54.6243 8.87732 53.2878 8.87732C49.8818 8.87732 47.8555 10.9899 47.8555 14.9995V23.7084C47.8555 27.6749 49.9249 29.8305 53.2878 29.8305C56.6938 29.8305 58.7201 27.718 58.7201 23.7084V23.1048L55.8746 23.3635V23.8377H55.9177Z"
      fill="white"
    />
    <path
      d="M137.532 29.8305C138.998 29.8305 140.249 29.4425 141.24 28.6665C141.542 28.4509 141.758 28.5371 141.758 28.9683V30.5203H143.267V21.294H140.421H138.222V22.6306H140.421V23.8377C140.421 27.5886 139.3 28.8389 137.576 28.8389C135.808 28.8389 134.989 27.5886 134.989 23.8377V14.8701C134.989 11.1192 135.851 9.86893 137.619 9.86893C139.214 9.86893 140.076 10.8605 140.206 13.8785L143.008 14.051V8.1875H141.499V9.52402C141.499 9.91204 141.24 10.0845 140.938 9.86893C140.076 9.26534 138.912 8.87732 137.576 8.87732C134.17 8.87732 132.143 10.9899 132.143 14.9995V23.7084C132.1 27.718 134.126 29.8305 137.532 29.8305Z"
      fill="white"
    />
    <path
      d="M40.8712 17.5863C39.1035 16.4653 37.1203 15.6462 37.1203 13.3611C37.1203 11.0761 38.155 9.91204 39.9227 9.91204C41.7335 9.91204 42.5095 11.1623 42.5095 13.8785L45.355 14.0941V8.1875H43.846V9.30845C43.846 9.69648 43.5873 9.82582 43.2856 9.65336C42.3371 9.09289 41.3454 8.87732 39.8796 8.87732C36.6029 8.87732 34.4473 10.3863 34.4473 14.051C34.4473 17.2845 37.0772 18.4485 39.2329 19.7851C41.8628 21.4665 43.1131 23.0186 43.1131 24.7C43.1131 27.1575 42.3802 28.7527 40.3538 28.7527C38.1119 28.7527 37.2928 26.6401 37.2928 23.3204L34.4473 23.5791C34.4473 27.5455 36.2149 29.7443 40.3538 29.7443C44.4927 29.7443 45.9155 27.5886 45.9155 24.1395C45.9155 20.8198 43.458 19.2246 40.8712 17.5863Z"
      fill="white"
    />
    <path
      d="M147.837 18.3619C145.336 18.5775 145.034 23.9236 148.354 25.1739L148.613 24.5703C147.88 23.7511 147.492 22.8457 147.319 21.6817C147.147 20.3882 147.362 19.1811 148.052 19.1811C149.346 19.1811 150.553 22.5008 149.82 25.6481C148.742 30.3906 145.121 33.6241 139.731 33.6241C134.299 33.6241 129.212 29.9595 128.953 22.4146V11.1188C128.953 10.6446 129.212 10.3859 129.686 10.2566C129.945 10.2134 130.074 10.1703 130.074 10.1703L130.16 9.17871H124.943L124.857 10.1703C124.857 10.1703 125.116 10.2134 125.375 10.2566C125.849 10.3859 126.107 10.6446 126.107 11.1188V23.8373C126.107 27.5882 125.245 28.8385 123.478 28.8385C121.71 28.8385 120.891 27.5882 120.891 23.8373V11.1619C120.891 10.6877 121.149 10.3859 121.624 10.2997C121.882 10.2566 122.012 10.2134 122.012 10.2134L122.098 9.22182H116.881L116.795 10.2134C116.795 10.2134 117.054 10.2566 117.312 10.2997C117.787 10.429 118.045 10.6877 118.045 11.1619V23.7511C118.045 27.7176 120.115 29.8732 123.478 29.8732C125.504 29.8732 127.056 29.0972 128.004 27.6313C128.134 27.4158 128.263 27.4158 128.349 27.5882C130.807 33.3223 135.506 34.6589 139.99 34.6589C145.94 34.6589 150.898 30.8217 151.674 25.605C152.364 21.2936 149.906 18.1463 147.837 18.3619Z"
      fill="white"
    />
  </svg>
</template>

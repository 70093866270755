<script lang="ts" setup=""></script>

<template>
  <footer class="w-full">
    <div class="bg-blurple-100 rounded-t-2xl text-neutral-white p-xxl">
      <div class="container mx-auto flex gap-3xl flex-col md:flex-row">
        <FooterNewsletterCta />
        <div class="flex flex-col gap-xl">
          <FooterColophon />
          <FooterProviderLogos />
        </div>
      </div>
    </div>
    <div class="bg-blurple-60 p-lg">
      <div
        class="container mx-auto flex flex-col sm:flex-row gap-3 justify-between items-center"
      >
        <div class="basis-[30%] text-center sm:text-start">
          Denne siden er beskyttet av reCAPTCHA og Googles
          <a
            href="#todo"
            class="underline"
          >personvernregler</a> og
          <a
            href="#todo"
            class="underline"
          >brukervilkår</a> gjelder.
        </div>
        <div class="basis-[40%] text-center">
          ©2024&nbsp;Juridika
        </div>
        <div class="fill-blurple-100 flex gap-sm basis-[30%] justify-end">
          <a href="https://www.facebook.com/JuridikaUF">
            <IconSocialFacebook class="size-md" />
          </a>
          <a href="https://www.instagram.com/juridika_">
            <IconSocialInstagram class="size-md" />
          </a>
          <a href="https://www.linkedin.com/company/juridika/">
            <IconSocialLinkedIn class="size-md" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

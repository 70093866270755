<script lang="ts" setup="">
import { KsInput, KsButton } from "@aschehoug/kloss";

async function handleSubmit(e: Event) {
  console.log(Object.fromEntries(new FormData(e.target as HTMLFormElement)));
}
</script>

<template>
  <section>
    <LogoJuridika class="mb-xl" />
    <form
      class="flex flex-col gap-md"
      @submit.prevent="handleSubmit"
    >
      <p>
        Meld deg på vårt nyhetsbrev og få varsler om nytt innhold og ny
        funksjonalitet.
      </p>
      <KsInput
        type="email"
        name="email"
        placeholder="Din e-postadresse"
        required
        class="text-carbon-100 !p-sm !rounded-lg"
      />
      <KsButton
        variant="primary"
        shape="rounded"
        type="submit"
        size="large"
      >
        Meld deg på
      </KsButton>
    </form>
  </section>
</template>

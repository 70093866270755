<script setup lang="ts">
const enabled = ref(false);
</script>

<template>
  <div class="layout">
    <LayoutHeader v-if="enabled" />
    <header v-else>
      header
    </header>
    <main>
      <slot />
    </main>
    <LayoutFooter v-if="enabled" />
    <footer v-else>
      footer
    </footer>
  </div>
</template>

<style scoped>
.layout {
  display: flex;
  flex-direction: column;
  width: 100dvw;
  height: 100dvh;

  > main {
    flex: 1;
  }
}
</style>

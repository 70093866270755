<template>
  <div class="flex flex-col gap-md">
    <p>En tjeneste fra</p>
    <div class="flex gap-md items-center flex-wrap">
      <LogoAschehoug />
      <LogoUniversitetsforlaget />
    </div>
  </div>
</template>

<script setup lang="ts"></script>
